import "./StoryBook.css";

import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  checkWaitlistStatus,
  getStoryLikeStatus,
  toggleLikeStory,
} from "../services/storyGenerationService";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StoryPage } from "../types";
import { getAuth } from "firebase/auth";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { useWhitelistStatus } from "../hooks/useWhitelistStatus";

interface StoryBookProps {
  pages: StoryPage[];
  storyId?: string;
  title?: string; // Add this line
}

const TheEndPage: React.FC<{ storyId: string }> = ({ storyId }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnWaitlist, setIsOnWaitlist] = useState(false);
  const auth = getAuth();
  const { isWhitelisted } = useWhitelistStatus();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLikeStatus = async () => {
      if (auth.currentUser) {
        try {
          const likeStatus = await getStoryLikeStatus(
            storyId,
            auth.currentUser.uid
          );
          setIsLiked(likeStatus);

          // Check waitlist status
          const waitlistStatus = await checkWaitlistStatus(
            auth.currentUser.uid
          );
          setIsOnWaitlist(waitlistStatus);
        } catch (error) {
          console.error("Error fetching status:", error);
        }
      }
    };

    fetchLikeStatus();
  }, [storyId, auth.currentUser]);

  const handleLike = async () => {
    if (!auth.currentUser) {
      // Handle unauthenticated user (e.g., show login prompt)
      return;
    }

    setIsLoading(true);
    try {
      await toggleLikeStory(storyId, auth.currentUser.uid);
      setIsLiked((prev) => !prev);
    } catch (error) {
      console.error("Error toggling like:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="the-end-page">
      <img
        src="/done_reading.png"
        alt="Done Reading"
        className="done-reading-image"
      />
      <h2>The End</h2>
      <button
        className={`like-story-button ${isLiked ? "liked" : ""}`}
        onClick={handleLike}
        disabled={isLoading}
      >
        <FontAwesomeIcon icon={isLiked ? solidHeart : regularHeart} />
        <span>{isLiked ? "Liked!" : "Like this story"}</span>
      </button>
      <div className="end-page-links">
        <Link to="/" className="browse-button">
          Browse More Stories
        </Link>
        {isWhitelisted ? (
          <Link to="/generate" className="create-story-button">
            Create Another Story
          </Link>
        ) : isOnWaitlist ? (
          <p className="waitlist-status">
            You're on the waitlist! We'll notify you when you can create
            stories.
          </p>
        ) : (
          <Link to="/waitlist" className="waitlist-link">
            Want to create your own stories? Join the waitlist!
          </Link>
        )}
      </div>
    </div>
  );
};

const TitlePage: React.FC<{ title: string }> = ({ title }) => (
  <div className="title-page">
    <h1 className="story-title">{title}</h1>
  </div>
);

const StoryPageComponent: React.FC<{
  page: StoryPage;
  pageNumber: number;
  totalPages: number;
}> = ({ page, pageNumber, totalPages }) => (
  <>
    <div className="page-content-container">
      <img src={page.imageUrl} alt={`Page ${pageNumber}`} draggable="false" />
      <p className="page-content">{page.content}</p>
    </div>
    <div className="page-number">{`${pageNumber} of ${totalPages}`}</div>
  </>
);

const StoryBook: React.FC<StoryBookProps> = ({ pages, storyId, title }) => {
  const navigate = useNavigate();
  const { pageNumber } = useParams<{ pageNumber: string }>();
  const [currentPage, setCurrentPage] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (pageNumber) {
      setCurrentPage(parseInt(pageNumber) - 1);
    } else {
      setCurrentPage(0);
      if (storyId) {
        navigate(`/story/${storyId}/1`, { replace: true });
      }
    }
  }, [pageNumber, storyId, navigate]);

  const [dragOffset, setDragOffset] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const dragStartX = useRef<number | null>(null);

  const handleSwipe = useCallback(
    (direction: "left" | "right") => {
      setIsTransitioning(true);
      setTimeout(() => {
        if (direction === "left" && currentPage < totalPageCount - 1) {
          const newPage = currentPage + 1;
          setCurrentPage(newPage);
          if (storyId) {
            navigate(`/story/${storyId}/${newPage + 1}`);
          }
        } else if (direction === "right" && currentPage > 0) {
          const newPage = currentPage - 1;
          setCurrentPage(newPage);
          if (storyId) {
            navigate(`/story/${storyId}/${newPage + 1}`);
          }
        }
        setDragOffset(0);
        setIsTransitioning(false);
      }, 300);
    },
    [currentPage, pages.length, navigate, storyId]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft" && currentPage > 0) {
        handleSwipe("right");
      } else if (event.key === "ArrowRight" && currentPage < pages.length) {
        handleSwipe("left");
      }
    },
    [currentPage, pages.length, handleSwipe]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleDragStart = (clientX: number) => {
    dragStartX.current = clientX;
  };

  const handleDrag = (clientX: number) => {
    if (dragStartX.current !== null && !isTransitioning) {
      const diff = clientX - dragStartX.current;
      setDragOffset(diff);
    }
  };

  const handleDragEnd = () => {
    if (Math.abs(dragOffset) > 100 && !isTransitioning) {
      handleSwipe(dragOffset > 0 ? "right" : "left");
    } else if (!isTransitioning) {
      setDragOffset(0);
    }
    dragStartX.current = null;
  };

  const isLastPage = currentPage === pages.length;

  const getNextPage = () => {
    if (dragOffset < 0 && currentPage < pages.length) {
      return currentPage + 1;
    } else if (dragOffset > 0 && currentPage > 0) {
      return currentPage - 1;
    }
    return null;
  };

  const nextPage = getNextPage();

  if (pages.length === 0) {
    return <div>No pages to display</div>;
  }

  const renderPage = (pageIndex: number) => {
    if (pageIndex === 0 && title) {
      return <TitlePage title={title} />;
    }
    if (pageIndex === totalPageCount - 1) {
      return <TheEndPage storyId={storyId || ""} />;
    }
    const adjustedIndex = title ? pageIndex - 1 : pageIndex;
    if (adjustedIndex >= 0 && adjustedIndex < pages.length) {
      return (
        <StoryPageComponent
          page={pages[adjustedIndex]}
          pageNumber={adjustedIndex + 1}
          totalPages={pages.length}
        />
      );
    }
    return null;
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  // Adjust the page count to include the title page and end page
  const totalPageCount = title ? pages.length + 2 : pages.length + 1;

  return (
    <div
      className="story-book"
      onTouchStart={(e) => handleDragStart(e.touches[0].clientX)}
      onTouchMove={(e) => handleDrag(e.touches[0].clientX)}
      onTouchEnd={handleDragEnd}
      onMouseDown={(e) => handleDragStart(e.clientX)}
      onMouseMove={(e) => dragStartX.current !== null && handleDrag(e.clientX)}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      tabIndex={0}
    >
      <button className="fullscreen-button" onClick={toggleFullscreen}>
        <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
      </button>
      {nextPage !== null && nextPage < totalPageCount && (
        <div
          className={`page next-page ${isTransitioning ? "transitioning" : ""}`}
          style={{
            opacity: Math.abs(dragOffset) / 500,
          }}
        >
          {renderPage(nextPage)}
        </div>
      )}
      <div
        className={`page current-page ${
          isTransitioning ? "transitioning" : ""
        }`}
        style={{
          transform: `translateX(${dragOffset}px) rotate(${
            dragOffset / 20
          }deg)`,
          opacity: 1 - Math.abs(dragOffset) / 500,
        }}
      >
        {renderPage(currentPage)}
      </div>
    </div>
  );
};

export default StoryBook;
