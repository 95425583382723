import "./App.css"; // You'll need to create this CSS file

import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import About from "./components/About"; // Add this line
import Auth from "./components/Auth";
import BrowseStories from "./components/BrowseStories";
import NavBar from "./components/NavBar"; // Add this import
import SavedStory from "./components/SavedStory";
import StoryGenerator from "./components/StoryGenerator";
import Waitlist from "./components/Waitlist";
import { useWhitelistStatus } from "./hooks/useWhitelistStatus";

const AppContent: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const { isWhitelisted, loading: whitelistLoading } = useWhitelistStatus();
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  // Check if the current route is a story page
  const isStoryPage = location.pathname.startsWith("/story/");

  if (whitelistLoading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      {!isStoryPage && <NavBar />}{" "}
      {/* Only show NavBar if not on a story page */}
      <div className={isStoryPage ? "App-full" : "App"}>
        <Routes>
          <Route
            path="/login"
            element={user ? <Navigate to="/" /> : <Auth />}
          />
          <Route
            path="/generate"
            element={
              user ? (
                isWhitelisted ? (
                  <StoryGenerator />
                ) : (
                  <Navigate to="/waitlist" />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/waitlist"
            element={
              user ? (
                isWhitelisted ? (
                  <Navigate to="/generate" />
                ) : (
                  <Waitlist />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/story/:storyId" element={<SavedStory />} />
          <Route path="/story/:storyId/:pageNumber" element={<SavedStory />} />
          <Route path="/about" element={<About />} /> {/* Add this line */}
          <Route path="/" element={<BrowseStories />} />
        </Routes>
      </div>
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
