import React, { useEffect, useState } from "react";
import { doc, getFirestore, setDoc } from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { logAnalyticsEvent } from "../services/storyGenerationService";

const Waitlist: React.FC = () => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const addToWaitlist = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const db = getFirestore();
        try {
          await setDoc(doc(db, "waitlist", user.uid), {
            email: user.email,
            timestamp: new Date(),
          });
          setMessage("You have been added to the waitlist!");
          logAnalyticsEvent("join_waitlist", { email: user.email });
        } catch (error) {
          setMessage("An error occurred. Please try again later.");
          logAnalyticsEvent("join_waitlist_error", {
            error: (error as Error).message,
          });
        }
      } else {
        setMessage("You must be logged in to join the waitlist.");
        logAnalyticsEvent("join_waitlist_error", {
          error: "User not logged in",
        });
      }
    };

    addToWaitlist();
  }, []);

  return (
    <div className="card-container">
      <div className="card">
        <h2>Waitlist Status</h2>
        {message && <p className="message">{message}</p>}
        <p>We'll notify you when you're granted access.</p>
      </div>
    </div>
  );
};

export default Waitlist;
