import { addDoc, arrayRemove, arrayUnion, collection, doc, limit as firestoreLimit, getDoc, getDocs, getFirestore, orderBy, query, setDoc, startAfter, updateDoc, where } from 'firebase/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyBV4EKLp-0PK_ASlU53hcDDc0kAg9UuvGM",
  authDomain: "ai-stories-c7e7d.firebaseapp.com",
  projectId: "ai-stories-c7e7d",
  storageBucket: "ai-stories-c7e7d.appspot.com",
  messagingSenderId: "646855595634",
  appId: "1:646855595634:web:7233eb644c2d6cdde514eb",
  measurementId: "G-GBP27S6X9B"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const analytics = getAnalytics(app);

export async function generateStoryWithImages(numPages: number, animal: string, lesson: string, userId: string): Promise<{ storyId: string }> {
  // Save the initial story parameters to Firestore
  const storyId = await saveStoryToFirestore(numPages, animal, lesson, userId);
  return { storyId };
}

export async function saveStoryToFirestore(
  numPages: number,
  animal: string,
  lesson: string,
  userId: string // Add userId parameter
): Promise<string> {
  try {
    const docRef = await addDoc(collection(db, 'stories'), {
      numPages,
      animal,
      lesson,
      createdAt: new Date(),
      status: 'pending',
      creatorId: userId, // Add creatorId field
    });
    console.log('Story parameters saved successfully with ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error saving story parameters to Firestore:', error);
    throw new Error('Failed to save story parameters: ' + (error as Error).message);
  }
}

export async function getStoryFromFirestore(storyId: string): Promise<any> {
  try {
    const docRef = doc(db, 'stories', storyId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw new Error('No such document!');
    }
  } catch (error) {
    console.error('Error fetching story from Firestore:', error);
    throw new Error('Failed to fetch story: ' + (error as Error).message);
  }
}

export async function toggleLikeStory(storyId: string, userId: string): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const likedStories = userData.likedStories || [];
      
      if (likedStories.includes(storyId)) {
        await updateDoc(userRef, {
          likedStories: arrayRemove(storyId)
        });
      } else {
        await updateDoc(userRef, {
          likedStories: arrayUnion(storyId)
        });
      }
    } else {
      // If the user document doesn't exist, create it
      await setDoc(userRef, {
        likedStories: [storyId]
      });
    }
  } catch (error) {
    console.error('Error toggling like:', error);
    throw new Error('Failed to toggle like: ' + (error as Error).message);
  }
}

export async function getStoryLikeStatus(storyId: string, userId: string): Promise<boolean> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const likedStories = userData.likedStories || [];
      return likedStories.includes(storyId);
    }
    
    return false;
  } catch (error) {
    console.error('Error getting story like status:', error);
    throw new Error('Failed to get story like status: ' + (error as Error).message);
  }
}

export const getPublishedStories = async (
  lastDoc: any = null,
  limitCount: number = 10,
  userId: string | null = null,
  likedOnly: boolean = false,
  myStoriesOnly: boolean = false
) => {
  const db = getFirestore();
  const storiesCollection = collection(db, 'stories');
  
  let likedStories: string[] = [];
  if (userId) {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      likedStories = userDoc.data().likedStories || [];
    }
  }
  
  if (likedOnly && likedStories.length === 0) {
    return { stories: [], lastVisible: null };
  }

  let q;
  if (likedOnly) {
    q = query(
      storiesCollection,
      where('__name__', 'in', likedStories)
    );
  } else if (myStoriesOnly && userId) {
    q = query(
      storiesCollection,
      where('creatorId', '==', userId),
      orderBy('createdAt', 'desc')
    );
  } else {
    q = query(storiesCollection, orderBy('createdAt', 'desc'));
  }

  if (lastDoc && !likedOnly) {
    q = query(q, startAfter(lastDoc));
  }
  q = query(q, firestoreLimit(limitCount));
  
  try {
    const querySnapshot = await getDocs(q);

    let stories = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        animal: data.animal,
        title: data.title,
        lesson: data.lesson,
        coverImageUrl: data.pages && data.pages[0] ? data.pages[0].imageUrl : null,
        isLiked: likedStories.includes(doc.id),
        createdAt: data.createdAt.toDate(),
      };
    });
    
    stories.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    
    if (likedOnly) {
      const startIndex = lastDoc ? stories.findIndex(story => story.id === lastDoc.id) + 1 : 0;
      stories = stories.slice(startIndex, startIndex + limitCount);
    }
    
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    return { stories, lastVisible };
  } catch (error) {
    console.error("Error in getPublishedStories:", error);
    throw error;
  }
};

// Add this function to log events
export const logAnalyticsEvent = (eventName: string, eventParams?: { [key: string]: any }) => {
  logEvent(analytics, eventName, eventParams);
};

export const checkWaitlistStatus = async (userId: string): Promise<boolean> => {
  const db = getFirestore();
  const waitlistRef = doc(db, "waitlist", userId);
  const waitlistDoc = await getDoc(waitlistRef);
  return waitlistDoc.exists();
};
