import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { getAuth } from 'firebase/auth';

export const useWhitelistStatus = () => {
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const isUserWhitelisted = await checkWhitelistStatus(user.uid);
        setIsWhitelisted(isUserWhitelisted);
      } else {
        setIsWhitelisted(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const checkWhitelistStatus = async (userId: string): Promise<boolean> => {
    const db = getFirestore();
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);
    return userSnap.exists() && userSnap.data().whitelisted === true;
  };

  return { isWhitelisted, loading };
};