import "./About.css"; // We'll create this CSS file next

import { Link } from "react-router-dom";
import React from "react";

const About: React.FC = () => {
  return (
    <div className="about-page">
      <div className="hero-banner">
        <img src="/wordmark.webp" alt="Wordmark" className="wordmark" />
      </div>
      <div className="about-content">
        <p>
          Welcome to our AI-powered children's story generator! We create
          unique, personalized stories tailored to your child's interests and
          the lessons you want them to learn.
        </p>
        <p>
          Our service uses magic sauce to craft engaging narratives and generate
          beautiful illustrations, making each story a one-of-a-kind adventure.
        </p>
        <p>
          Whether you're looking for a bedtime story, a teaching tool, or just a
          fun read, our platform offers endless possibilities for creativity and
          learning.
        </p>
        <div className="cta-buttons">
          <Link to="/generate" className="cta-button secondary">
            Create a Story
          </Link>
          <Link to="/" className="cta-button secondary">
            Browse Stories
          </Link>
        </div>
        <p className="made-with-love">Made with ❤️ by Diana, Claude, and Ben</p>
      </div>
    </div>
  );
};

export default About;
