import React, { useEffect, useState } from "react";

import StoryBook from "./StoryBook";
import { StoryPage } from "../types";
import { getStoryFromFirestore } from "../services/storyGenerationService";
import { logAnalyticsEvent } from "../services/storyGenerationService";
import { useParams } from "react-router-dom";

const SavedStory: React.FC = () => {
  const { storyId, pageNumber } = useParams<{
    storyId: string;
    pageNumber: string;
  }>();
  const [pages, setPages] = useState<StoryPage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [title, setTitle] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchStory = async () => {
      if (!storyId) return;
      try {
        let storyData;
        let attempts = 0;
        const maxAttempts = 5 * 60; // 5 minutes (5 * 60 seconds)
        const interval = 1000; // 1 seconds

        while (attempts < maxAttempts) {
          storyData = await getStoryFromFirestore(storyId);

          if (storyData.status === "ready") {
            const storyPages = storyData.pages.map(
              (page: any, index: number) => ({
                id: index + 1,
                content: page.text,
                imageUrl: page.imageUrl,
              })
            );
            setPages(storyPages);
            setTitle(storyData.title); // Add this line

            // Preload images
            const imagePromises = storyPages.map((page: StoryPage) => {
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = page.imageUrl;
                img.onload = resolve;
                img.onerror = reject;
              });
            });

            Promise.all(imagePromises)
              .then(() => setImagesLoaded(true))
              .catch((error) =>
                console.error("Error preloading images:", error)
              );

            setIsLoading(false);
            logAnalyticsEvent("view_story", { storyId });
            break;
          } else {
            attempts++;
            await new Promise((resolve) => setTimeout(resolve, interval));
          }
        }

        if (attempts >= maxAttempts) {
          throw new Error("Story generation timed out");
        }
      } catch (error) {
        console.error("Error fetching story:", error);
        setError("Failed to load the story. Please try again later.");
        logAnalyticsEvent("view_story_error", {
          storyId,
          error: (error as Error).message,
        });
        setIsLoading(false);
      }
    };

    fetchStory();
  }, [storyId]);

  if (isLoading || !imagesLoaded) {
    return (
      <div className="loading">
        <img src="/loading.gif" alt="Loading" className="loading-logo" />
        <p>Creating your story...</p>
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!storyId) {
    return <div className="error">Invalid story ID</div>;
  }

  return (
    <div className="saved-story">
      {pages.length > 0 ? (
        <StoryBook pages={pages} storyId={storyId} title={title} />
      ) : (
        <div className="message">No story found</div>
      )}
    </div>
  );
};

export default SavedStory;
