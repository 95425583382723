import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth } from "firebase/auth";
import { logAnalyticsEvent } from "../services/storyGenerationService";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { toggleLikeStory } from "../services/storyGenerationService";

interface LikeButtonProps {
  storyId: string;
  initialLikeStatus?: boolean;
  onAuthRequired?: () => void;
}

const LikeButton: React.FC<LikeButtonProps> = ({
  storyId,
  initialLikeStatus = false,
  onAuthRequired,
}) => {
  const [isLiked, setIsLiked] = useState(initialLikeStatus);
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    setIsLiked(initialLikeStatus);
  }, [initialLikeStatus]);

  const handleLikeToggle = async () => {
    if (!auth.currentUser) {
      onAuthRequired?.();
      return;
    }

    setIsLoading(true);
    try {
      await toggleLikeStory(storyId, auth.currentUser.uid);
      const newLikeStatus = !isLiked;
      setIsLiked(newLikeStatus);
      logAnalyticsEvent("toggle_like", { storyId, liked: newLikeStatus });
    } catch (error) {
      console.error("Error toggling like:", error);
      logAnalyticsEvent("toggle_like_error", {
        storyId,
        error: (error as Error).message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className={`like-button ${isLiked ? "liked" : ""}`}
      onClick={handleLikeToggle}
      disabled={isLoading}
    >
      <FontAwesomeIcon icon={isLiked ? solidHeart : regularHeart} />
    </button>
  );
};

export default LikeButton;

export {};
