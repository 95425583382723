import "../App.css";

import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroll-component";
import LikeButton from "./LikeButton";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { getAuth } from "firebase/auth";
import { getPublishedStories } from "../services/storyGenerationService";
import { logAnalyticsEvent } from "../services/storyGenerationService";
import { useWhitelistStatus } from "../hooks/useWhitelistStatus";

interface Story {
  id: string;
  title: string;
  animal: string;
  lesson: string;
  coverImageUrl: string;
  isLiked: boolean;
}

const BrowseStories: React.FC = () => {
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [filterOption, setFilterOption] = useState("all");
  const auth = getAuth();
  const navigate = useNavigate();
  const { isWhitelisted, loading: whitelistLoading } = useWhitelistStatus();

  const fetchStories = useCallback(
    async (lastVisibleToUse: any = null) => {
      console.log("Fetching stories...");
      setLoading(true);
      try {
        const { stories: fetchedStories, lastVisible: fetchedLastVisible } =
          await getPublishedStories(
            lastVisibleToUse,
            10,
            auth.currentUser?.uid || null,
            filterOption === "liked",
            filterOption === "my"
          );
        console.log("Fetched stories:", fetchedStories);
        setStories((prevStories) =>
          lastVisibleToUse === null
            ? fetchedStories
            : [...prevStories, ...fetchedStories]
        );
        setLastVisible(fetchedLastVisible);
        setHasMore(fetchedStories.length === 10);
      } catch (err) {
        console.error("Error fetching stories:", err);
        setError("Failed to load stories. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
    [filterOption, auth.currentUser?.uid]
  );

  useEffect(() => {
    setStories([]);
    setLastVisible(null);
    setHasMore(true);
    fetchStories(null);
  }, [fetchStories]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilterOption = event.target.value;
    setFilterOption(newFilterOption);
    logAnalyticsEvent("filter_stories", { filter: newFilterOption });
  };

  const handleAuthRequired = () => {
    const confirmSignIn = window.confirm(
      "Please sign in to like stories. Would you like to sign in now?"
    );
    if (confirmSignIn) {
      navigate("/login");
    }
  };

  if (error) return <div className="error">{error}</div>;

  return (
    <div className="browse-stories">
      <div className="filter-container">
        <div className="filter-left">
          <FontAwesomeIcon icon={faFilter} />
          <select value={filterOption} onChange={handleFilterChange}>
            <option value="all">All Stories</option>
            <option value="liked">Liked Stories</option>
            <option value="my">My Stories</option>
          </select>
        </div>
      </div>
      <InfiniteScroll
        dataLength={stories.length}
        next={() => {
          fetchStories(lastVisible);
        }}
        hasMore={hasMore}
        loader={<h4>Loading more stories...</h4>}
        endMessage={<p>No more stories to load.</p>}
      >
        <div className="story-grid">
          {loading && stories.length === 0 ? (
            <p>Loading...</p>
          ) : stories.length === 0 ? (
            <p>No stories found.</p>
          ) : (
            stories.map((story) => (
              <div key={story.id} className="story-thumbnail-container">
                <Link to={`/story/${story.id}/1`} className="story-thumbnail">
                  <img src={story.coverImageUrl} alt={story.title} />
                  <p>{story.title || story.animal}</p>
                  <div className="story-lesson-hover">
                    <p>{story.lesson}</p>
                  </div>
                </Link>
                <LikeButton
                  storyId={story.id}
                  initialLikeStatus={story.isLiked}
                  onAuthRequired={handleAuthRequired}
                />
              </div>
            ))
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default BrowseStories;
