import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import React, { useState } from "react";

import { logAnalyticsEvent } from "../services/storyGenerationService";
import { useNavigate } from "react-router-dom";

const Auth: React.FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    setError("");
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      if (!user.email) {
        setError("You are not authorized to use this application.");
        return;
      }
      logAnalyticsEvent("login", { method: "google" });
      navigate("/");
    } catch (error) {
      setError((error as Error).message);
      logAnalyticsEvent("login_error", { error: (error as Error).message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card-container">
      <div className="card">
        <h2>Welcome to AI Story Generator</h2>
        <p>Log in to create personalized stories for children</p>
        <button
          onClick={handleGoogleSignIn}
          className="google-sign-in"
          disabled={loading}
        >
          <span className="google-icon"></span>
          {loading ? "Signing in..." : "Sign in with Google to Start"}
        </button>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default Auth;
