import "./NavBar.css"; // Add this import

import React, { useState } from "react";
import {
  faBars,
  faSignOutAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { getAuth, signOut } from "firebase/auth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { logAnalyticsEvent } from "../services/storyGenerationService";
import { useNavigate } from "react-router-dom";
import { useWhitelistStatus } from "../hooks/useWhitelistStatus";

const NavBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isWhitelisted } = useWhitelistStatus();
  const auth = getAuth();
  const navigate = useNavigate();

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      logAnalyticsEvent("logout");
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
      logAnalyticsEvent("logout_error", { error: (error as Error).message });
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">
          <img
            src="/wordmark_landscape_tight.png"
            alt="StoryAI"
            className="navbar-logo"
          />
        </Link>
      </div>
      <button className="navbar-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </button>
      <ul className={`navbar-menu ${isOpen ? "is-open" : ""}`}>
        <li>
          <Link to="/about" onClick={toggleMenu}>
            About
          </Link>
        </li>
        {isWhitelisted && (
          <li>
            <Link to="/generate" onClick={toggleMenu}>
              Create
            </Link>
          </li>
        )}
        {auth.currentUser ? (
          <li>
            <div onClick={handleLogout} className="logout-link">
              <FontAwesomeIcon icon={faSignOutAlt} /> Logout
            </div>
          </li>
        ) : (
          <li>
            <Link to="/login" onClick={toggleMenu}>
              Login
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default NavBar;
