import "../App.css";

import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { getAuth, signOut } from "firebase/auth";
// Add these new imports
import { sampleAnimals, sampleLessons } from "../data/sampleData";

import StoryBook from "./StoryBook";
import { StoryPage } from "../types";
import { generateStoryWithImages } from "../services/storyGenerationService";
import { logAnalyticsEvent } from "../services/storyGenerationService";

const MIN_PAGES = 1;
const MAX_PAGES = 20;
const DEFAULT_PAGES = 5;

const StoryGenerator: React.FC = () => {
  const [pages, setPages] = useState<StoryPage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<string>(() => {
    const storedValue =
      localStorage.getItem("numPages") || DEFAULT_PAGES.toString();
    return Math.min(
      Math.max(parseInt(storedValue, 10), MIN_PAGES),
      MAX_PAGES
    ).toString();
  });
  const [animal, setAnimal] = useState(
    () => localStorage.getItem("animal") || "penguin"
  );
  const [lesson, setLesson] = useState(
    () => localStorage.getItem("lesson") || "sharing is caring"
  );

  const [animals, setAnimals] = useState<string[]>(sampleAnimals);
  const [lessons, setLessons] = useState<string[]>(sampleLessons);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("numPages", numPages);
    localStorage.setItem("animal", animal);
    localStorage.setItem("lesson", lesson);
  }, [numPages, animal, lesson]);

  const isFormValid = () => {
    return (
      numPages !== "" &&
      parseInt(numPages, 10) >= MIN_PAGES &&
      parseInt(numPages, 10) <= MAX_PAGES &&
      animal.trim() !== "" &&
      lesson.trim() !== ""
    );
  };

  const generateStoryBook = async () => {
    if (!isFormValid()) {
      setError("Please fill in all fields correctly before creating a story.");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("User not authenticated");
      }

      logAnalyticsEvent("generate_story_start", {
        numPages: parseInt(numPages, 10),
        animal,
        lesson,
      });

      const { storyId } = await generateStoryWithImages(
        parseInt(numPages, 10),
        animal,
        lesson,
        user.uid
      );

      logAnalyticsEvent("generate_story_success", { storyId });
      navigate(`/story/${storyId}/1`);
    } catch (error) {
      console.error("Error generating story:", error);
      setError("Failed to start story generation. Please try again later.");
      logAnalyticsEvent("generate_story_error", {
        error: (error as Error).message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [isRollingAnimal, setIsRollingAnimal] = useState(false);
  const [isRollingLesson, setIsRollingLesson] = useState(false);

  const handleRandomAnimal = () => {
    setIsRollingAnimal(true);
    setTimeout(() => {
      const randomIndex = Math.floor(Math.random() * animals.length);
      setAnimal(animals[randomIndex]);
      setIsRollingAnimal(false);
    }, 200); // Changed from 500 to 200 milliseconds (0.2 seconds)
  };

  const handleRandomLesson = () => {
    setIsRollingLesson(true);
    setTimeout(() => {
      const randomIndex = Math.floor(Math.random() * lessons.length);
      setLesson(lessons[randomIndex]);
      setIsRollingLesson(false);
    }, 200); // Changed from 500 to 200 milliseconds (0.2 seconds)
  };

  const animalRef = useRef<HTMLTextAreaElement>(null);
  const lessonRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    if (animalRef.current) adjustTextareaHeight(animalRef.current);
    if (lessonRef.current) adjustTextareaHeight(lessonRef.current);
  }, [animal, lesson]);

  const handleNumPagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue)) {
      const clampedValue = Math.min(
        Math.max(parsedValue, MIN_PAGES),
        MAX_PAGES
      );
      setNumPages(clampedValue.toString());
    } else {
      setNumPages("");
    }
  };

  return (
    <>
      <div className="card-container">
        <div className="card">
          <h1>Create your own!</h1>
          <div className="input-group">
            <label htmlFor="numPages">Number of pages:</label>
            <input
              id="numPages"
              type="number"
              value={numPages}
              onChange={handleNumPagesChange}
              min={MIN_PAGES}
              max={MAX_PAGES}
            />
          </div>
          <div className="input-group">
            <label htmlFor="animal">Animal:</label>
            <div className="input-with-button">
              <textarea
                id="animal"
                ref={animalRef}
                value={animal}
                onChange={(e) => {
                  setAnimal(e.target.value);
                  adjustTextareaHeight(e.target);
                }}
                rows={1}
              />
              <button
                onClick={handleRandomAnimal}
                className={`random-button ${isRollingAnimal ? "rolling" : ""}`}
                disabled={isRollingAnimal}
              >
                🎲
              </button>
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="lesson">Lesson:</label>
            <div className="input-with-button">
              <textarea
                id="lesson"
                ref={lessonRef}
                value={lesson}
                onChange={(e) => {
                  setLesson(e.target.value);
                  adjustTextareaHeight(e.target);
                }}
                rows={1}
              />
              <button
                onClick={handleRandomLesson}
                className={`random-button ${isRollingLesson ? "rolling" : ""}`}
                disabled={isRollingLesson}
              >
                🎲
              </button>
            </div>
          </div>
          <button
            onClick={generateStoryBook}
            disabled={isLoading || !isFormValid()}
          >
            {isLoading ? "Creating..." : "Create"}
          </button>
          <Link to="/" className="browse-link">
            Browse
          </Link>
          {error && <div className="error">{error}</div>}
        </div>
        {isLoading && <div className="loading">Loading your story...</div>}
        {pages.length > 0 && <StoryBook pages={pages} />}
      </div>
    </>
  );
};

export default StoryGenerator;
